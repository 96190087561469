// import Multiselect from 'vue-multiselect';

// require('./bootstrap');

// window.Vue = require('vue');

// Layout
// Vue.component('d-navbar', require('./components/layout/Navbar.vue').default);
// Vue.component('d-topbar', require('./components/layout/Topbar.vue').default);
// Vue.component('d-sidebar-item', require('./components/layout/SidebarItem.vue').default);
// Vue.component('d-card', require('./components/layout/Card.vue').default);
// // Form
// Vue.component('d-field', require('./components/form/Field.vue').default);
// Vue.component('d-label', require('./components/form/Label.vue').default);
// Vue.component('d-inputtext', require('./components/form/InputText.vue').default);
// Vue.component('d-select', require('./components/form/Select.vue').default);
// Vue.component('d-multiselect', require('./components/form/DMultiSelect.vue').default);
// Vue.component('d-button', require('./components/form/Button.vue').default);
// Vue.component('d-checkbox', require('./components/form/Checkbox.vue').default);
//
// Vue.component('d-menu', require('./components/Menu.vue').default);
// Vue.component('d-sortablelist', require('./components/SortableList.vue').default);
// Vue.component('d-html-editor', require('./components/form/HTMLEditor.vue').default);
// Vue.component('d-html-editor-old', require('./components/form/HTMLEditorOld.vue').default);
// Editor
// Vue.component('d-editor-paragraph', require('./components/editor/Paragraph.vue').default);

// Vue.component('multiselect', Multiselect);

// const app = new Vue({
//     el: '#app',
//     mounted() {
//         // document.querySelector('#fs_loading').classList.add('hidden');
//     }
// });


$(document).ready(function () {
    mask('99999-999').mask($('.cep'));
    mask('999.999.999-99').mask($('.cpf'));
    mask('(99) 9999[9]-9999', {skipOptionalPartCharacter: ' ', greedy: false}).mask($('.telefone'));
    $('#btn-menu-mobile').on('click', function () {
        $('#menu-mobile').show();
    });
    $('#hide-menu-mobile').on('click', function () {
        $('#menu-mobile').hide();
    });
    $("#menu-toggle").click(function (e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
    });
});
